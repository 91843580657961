import React from "react";
import Layout from "../comps/Layout";
import Hero from "../comps/Hero";
import Section from "../comps/Section";
import Stack from "../comps/Stack";
import Stat from "../comps/Stat";
import Puff from "../comps/Puff";
import Button from "../comps/Button";
import SectionHeading from "../comps/SectionHeading";
import Logo from "../comps/Logo";

// markup
const IndexPage = () => {
  return (
    <div>
      <title>Start - Astea</title>
      <Logo />
    </div>
  );
};

export default IndexPage;
